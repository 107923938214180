.accounts-table a {
  display: block;
  text-decoration: none;
  color: black;
}

.accounts-table .link {
  font-weight: bold;
  text-decoration: underline;
}

.accounts-table th {
  white-space: nowrap;
}

.accounts-table th:nth-child(3),
.accounts-table td:nth-child(3),
.accounts-table th:nth-child(4),
.accounts-table td:nth-child(4) {
  text-align: end;
  white-space: nowrap;
}

.accounts-table th:nth-child(5),
.accounts-table td:nth-child(5) {
  text-align: center;
}
