.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: var(--bs-link-color);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination-container .number {
  text-decoration: underline;
}
.pagination-container .pagination-item:hover {
  cursor: pointer;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item.selected {
  color: black;
  text-decoration: none;
  font-weight: bold;
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}
