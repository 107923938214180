body {
  font-family: 'Questrial', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.not-found-page {
  height: 50vh;
}

.metrics-number {
  margin-bottom: 0;
  font-size: 200%;
}

.metrics-number .money-amount {
  font-size: 75% !important;
}
