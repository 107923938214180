.account-info {
  background-image: url("../../assets/background-logo.png");
}

.tab-button-short {
  display: none;
}

@media (max-width: 475px) {
  .tab-button-full {
    display: none;
  }
  .tab-button-short {
    display: inline-block;
  }
}

.separator {
    border-left: 2px solid;
    padding-left: .5rem;
}

.update-icon {
  font-size: 1.5em;
}

.account-details > dt,
dd {
  border-bottom: 1px solid;
  margin: 0;
}

.account-details > dt::after {
  content: ':';
}

.transactions-table {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 0.9em;
}

.transactions-table th,
.analysis-table th {
  white-space: nowrap;
}

.transactions-table th:nth-child(2),
.transactions-table td:nth-child(2) {
  text-align: end;
}

.analysis {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

.analysis-table th,
.analysis-table td {
  text-align: end;
}

.analysis-table th:nth-child(1),
.analysis-table td:nth-child(1) {
  text-align: start;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tab-button {
  white-space: nowrap;
  width: 200px;
  font-size: 1.3em;
  border-radius: 0;
  color: black;
  background: #d9d9d9;
  border: 0;
}

@media (max-width: 475px) {
  .tab-button {
    width: 160px;
  }
}

.tab-button:hover,
.tab-button:active {
  color: black;
  background: rgba(147, 143, 143, 1);
}

.tab-button-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tab-button-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tab-button-active {
  background: rgba(147, 147, 147, 1);
}
